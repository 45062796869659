html,
body {
  margin: 0;
  padding: 0;
  font-family: "Readex Pro", sans-serif;
}

.app {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app h1 {
  font-size: 10rem;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 1rem;
}

.app h2 {
  font-size: 1.5rem;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 1rem;
}
